<template>
  <div class="box">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>维修业务列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="临时维修" name="temp">
          <div class="nav">
            <div class="main">
              <div class="titles">订单编号</div>
              <el-input style="width: 300px" placeholder="请输入订单编号" v-model="ticket_order_no" class="input-with-select" clearable @clear="linhandleEmptyorder">
                <el-button slot="append" icon="el-icon-search" @click="ticketOrdernofun()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">客户名称</div>
              <el-input style="width: 300px" placeholder="请输入客户名称" v-model="customer_name" class="input-with-select" clearable @clear="linhandleEmptyname">
                <el-button slot="append" icon="el-icon-search" @click="ticketnamefun()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">业务公司</div>
              <el-select v-model="business_company" placeholder="请选择" clearable @clear="linhandleEmptycompany">
                <el-option v-for="item in companis" :key="item.value" :label="item.name" :value="item.value" @click.native="businesschenge(item)"></el-option>
              </el-select>
            </div>
            <div class="main">
              <div class="titles">服务工程师</div>
              <div>
                <!-- <el-select v-model="maintain_user" placeholder="未选择" @clear="linhandleEmptyuser" clearable>
                  <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item)"></el-option>
                </el-select> -->
                <el-autocomplete
                  clearable
                  @clear="linhandleEmptyuser"
                  :trigger-on-focus="false"
                  placeholder="请输入工程师名称"
                  v-model="lingongchengs"
                  :fetch-suggestions="linvalidateCounts"
                  value-key="name"
                  @select="linhandleSelect($event, '申请人')"
                ></el-autocomplete>
              </div>
            </div>
          </div>
          <el-button type="primary" @click="download">导出维修记录</el-button>
          <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
            >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="ticket_order_no" label="订单编号" width="180"></el-table-column>
            <el-table-column prop="work_order_no" label="工单编号" width="200"></el-table-column>
            <el-table-column prop="business_company_name" label="业务公司" width="180"></el-table-column>
            <el-table-column prop="customer_name" label="客户名称" width="220"></el-table-column>
            <el-table-column prop="contact_address" label="客户地址" align="center" width="150"></el-table-column>
            <el-table-column prop="maintain_user" label="服务工程师" align="center" width="150"></el-table-column>
            <el-table-column prop="maintain_type_desc" label="维修类型" width="150" align="center"></el-table-column>
            <el-table-column label="营收(元)" align="center" width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.amount / 100 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180px" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" icon="el-icon-view" @click="detail(scope.row.work_order_id)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Fenye :page-data="temppageData" @newsize="tempbianzhi" @newpape="tempbianye" />
        </el-tab-pane>

        <!-- 合同维修 -->
        <el-tab-pane label="合同维修" name="contract">
          <div class="nav">
            <div class="main">
              <div class="titles">订单编号</div>
              <el-input style="width: 300px" placeholder="请输入订单编号" v-model="heticket_order_no" class="input-with-select" clearable @clear="hehandleEmptyheticket">
                <el-button slot="append" icon="el-icon-search" @click="heticketOrdernofun()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">客户名称</div>
              <el-input style="width: 300px" placeholder="请输入客户名称" v-model="hecustomer_name" class="input-with-select" clearable @clear="hehandleEmptyname">
                <el-button slot="append" icon="el-icon-search" @click="heticketnamefun()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">业务公司</div>
              <el-select v-model="hebusiness_company" placeholder="请选择" clearable @clear="hehandleEmptycompany">
                <el-option v-for="item in companis" :key="item.value" :label="item.name" :value="item.value" @click.native="hebusinesschenge(item)"></el-option>
              </el-select>
            </div>
          </div>
          <div class="nav">
            <div class="main">
              <div class="titles">合同编号</div>
              <el-input style="width: 300px" placeholder="请输入合同编号" v-model="hebian" class="input-with-select" clearable>
                <el-button slot="append" icon="el-icon-search" @click="hebianbtn()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">合同名称</div>
              <el-input style="width: 300px" placeholder="请输入合同名称" v-model="hename" class="input-with-select" clearable>
                <el-button slot="append" icon="el-icon-search" @click="henamebtn()"></el-button>
              </el-input>
            </div>
            <div class="main">
              <div class="titles">服务工程师</div>
              <div>
                <!-- <el-select v-model="hemaintain_user" placeholder="未选择" @clear="hehandleEmptyuser" clearable>
                  <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="hechoicedrug(item)"></el-option>
                </el-select> -->
                <el-autocomplete
                  clearable
                  @clear="hehandleEmptyuser"
                  :trigger-on-focus="false"
                  placeholder="请输入工程师名称"
                  v-model="hegongchengs"
                  :fetch-suggestions="hevalidateCounts"
                  value-key="name"
                  @select="hehandleSelect($event, '申请人')"
                ></el-autocomplete>
              </div>
            </div>
          </div>
          <el-button type="primary" @click="hedownload">导出维修记录</el-button>
          <el-table :data="contableData" stripe style="width: 100%" @selection-change="SelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="contract_no" label="合同编号" width="180"></el-table-column>
            <el-table-column prop="contract_name" label="合同名称"></el-table-column>
            <el-table-column prop="ticket_order_no" label="订单编号" width="180"></el-table-column>
            <el-table-column prop="business_company_name" label="业务公司" width="180"></el-table-column>
            <el-table-column prop="customer_name" label="客户名称" width="220"></el-table-column>
            <el-table-column prop="contact_address" label="客户地址" align="center" width="150"></el-table-column>
            <el-table-column prop="maintain_user" label="服务工程师" align="center" width="150"></el-table-column>
            <el-table-column prop="maintain_type_desc" label="维修类型" width="150" align="center"></el-table-column>
            <el-table-column label="成本(元)" align="center" width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.amount / 100 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180px" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" icon="el-icon-view" @click="detail(scope.row.work_order_id)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Fenye :page-data="contractpageData" @newsize="contbianzhi" @newpape="contbianye" />
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!-- 维修工单详情 -->
    <el-dialog width="50%" title="售后工单完成详情" :visible.sync="innerVisible" append-to-body center>
      <el-form :model="worklist">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="服务工程师" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.order_user_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修时间" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.create_time" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="维修设备名称" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.device_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修设备生产日期" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.device_date" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="故障描述" label-width="125px">
              <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.fault_desc" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修方案" label-width="125px">
              <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.maintain_scheme" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="故障照片" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in worklist.fault_images"
                :key="index"
                :src="item"
                :preview-src-list="worklist.fault_images"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="完成图片" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in worklist.maintain_images"
                :key="index"
                :src="item"
                :preview-src-list="worklist.maintain_images"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="hint">货物消耗</div>
      <el-table :data="worklist.goods_items" border style="width: 85%; margin-left: 10%">
        <el-table-column prop="goods_no" label="编号" width="130" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称" width="200" align="center"></el-table-column>
        <el-table-column prop="goods_desc" label="货物详情" align="center" width=""></el-table-column>
        <el-table-column label="单价(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.goods_price / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
        <el-table-column label="小计(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="tongs" style="hight: 30px">总计{{ worklist.amount / 100 }}元</div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="innerVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="innerVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import axios from 'axios'
import { Getren } from '../../../api/shouhuo.js'
import Fenye from '../../../components/Fenye'
import { Workorderlist, Workdetail, Options, Exports } from '../../../api/afterOrder.js'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      caigoulist: [],
      hegongchengs: '',
      lingongchengs: '',
      worklist: {},
      innerVisible: false,
      activeName: 'temp',
      tableData: [],
      contableData: [],
      temppageData: {
        page: 1,
        size: 10,
        count: 0
      },
      contractpageData: {
        page: 1,
        size: 10,
        count: 0
      },
      companis: [],
      renlist: [],
      ///////筛选
      types: 'temp',
      ticket_order_no: '',
      business_company: '',
      maintain_user: '',
      customer_name: '',
      heticket_order_no: '',
      hecustomer_name: '',
      hebian: '',
      hename: '',
      hebusiness_company: '',
      hemaintain_user: '',
      formInline: {
        type: 'temp',
        ids: []
      },
      heformInline: {
        type: 'contract',
        ids: []
      }
    }
  },
  mounted() {
    this.temporderlists()
    this.Optionss()
    this.Getrenlist()
  },
  methods: {
    // 搜索工程师后的数据
    linvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 搜索工程师后的数据
    hevalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 选中工程师
    linhandleSelect(e) {
      this.maintain_user = e.value
      this.temporderlists()
    },

    // 选中工程师
    hehandleSelect(e) {
      this.hemaintain_user = e.value
      this.contractorderlists()
    },

    linhandleEmptyorder() {
      this.ticket_order_no = ''
      this.temporderlists()
    },

    linhandleEmptyname() {
      this.customer_name = ''
      this.temporderlists()
    },

    linhandleEmptycompany() {
      this.business_company = ''
      this.temporderlists()
    },

    linhandleEmptyuser() {
      this.maintain_user = ''
      this.lingongchengs = ''
      this.temporderlists()
    },

    hehandleEmptyheticket() {
      this.heticket_order_no = ''
      this.contractorderlists()
    },

    hehandleEmptyname() {
      this.hecustomer_name = ''

      this.contractorderlists()
    },

    hehandleEmptycompany() {
      this.hebusiness_company = ''
      this.contractorderlists()
    },

    hehandleEmptyuser() {
      this.hemaintain_user = ''
      this.contractorderlists()
    },
    // 导出
    async download() {
      if (this.formInline.ids.length === 0) {
        this.$message({
          message: '请选择数据之后在导出',
          type: 'warning'
        })
      } else {
        const { data } = await Exports({ ...this.formInline })
        // console.log(data)
        let fileName = '维修记录'
        let extName = '.xlsx'
        // console.log(URL.createObjectURL(data))
        const link = document.createElement('a')
        link.download = fileName + extName
        link.href = URL.createObjectURL(data)
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      }
    },

    async hedownload() {
      if (this.heformInline.ids.length === 0) {
        this.$message({
          message: '请选择数据之后在导出',
          type: 'warning'
        })
      } else {
        const { data } = await Exports({ ...this.heformInline })
        // console.log(data)
        let fileName = '维修记录'
        let extName = '.xlsx'
        // console.log(URL.createObjectURL(data))
        const link = document.createElement('a')
        link.download = fileName + extName
        link.href = URL.createObjectURL(data)
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      }
    },

    // 临时维修选中
    handleSelectionChange(val) {
      // console.log(val)
      this.formInline.ids = []
      val.forEach(item => {
        this.formInline.ids.push(item.work_order_id)
      })
    },

    SelectionChange(val) {
      // console.log(val)
      this.heformInline.ids = []
      val.forEach(item => {
        this.heformInline.ids.push(item.work_order_id)
      })
    },

    // 订单编号筛选
    ticketOrdernofun() {
      if (this.ticket_order_no === '') {
        this.$message({
          message: '请输入订单编号',
          type: 'warning'
        })
      } else {
        this.temporderlists()
      }
    },

    // 合同维修订单编号筛选
    heticketOrdernofun() {
      if (this.heticket_order_no === '') {
        this.$message({
          message: '请输入订单编号',
          type: 'warning'
        })
      } else {
        this.contractorderlists()
      }
    },

    // 临时维修单编号筛选
    ticketnamefun() {
      if (this.customer_name === '') {
        this.$message({
          message: '请输入客户名称',
          type: 'warning'
        })
      } else {
        this.temporderlists()
      }
    },

    // 合同维修订单编号筛选
    heticketnamefun() {
      if (this.hecustomer_name === '') {
        this.$message({
          message: '请输入客户名称',
          type: 'warning'
        })
      } else {
        this.contractorderlists()
      }
    },

    // 维修工程师
    choicedrug() {
      this.temporderlists()
    },

    // 合同维修工程师
    hechoicedrug() {
      this.contractorderlists()
    },

    // 合同编号
    hebianbtn() {
      console.log('合同编号')
    },

    // 合同名称
    henamebtn() {
      console.log('合同名称')
    },

    // 删除
    handleEmpty() {
      this.ticket_order_no = ''
      this.business_company = ''
      this.temporderlists()
    },

    // 合同维修删除
    hehandleEmpty() {
      this.heticket_order_no = ''
      this.hecustomer_name = ''
      this.hebian = ''
      this.hename = ''
      this.hebusiness_company = ''
      this.hemaintain_user = ''
      this.contractorderlists()
    },

    // 业务公司
    businesschenge(e) {
      this.temporderlists()
    },

    // 合同业务公司
    hebusinesschenge(e) {
      this.contractorderlists()
    },

    // 详情按钮
    detail(work_order_id) {
      //   console.log(work_order_id)
      this.innerVisible = true
      this.Workdetails(work_order_id)
    },

    tempbianzhi(newSize) {
      // 分页值变化
      this.temppageData.size = newSize
      this.temporderlists()
    },

    tempbianye(newPape) {
      // 分页页数变化
      this.temppageData.page = newPape
      this.temporderlists()
    },

    contbianzhi(newSize) {
      // 分页值变化
      this.contractpageData.size = newSize
      this.contractorderlists()
    },

    contbianye(newPape) {
      // 分页页数变化
      this.contractpageData.page = newPape
      this.contractorderlists()
    },

    handleClick(tab) {
      //   console.log(tab.name)
      if (tab.name === 'temp') {
        this.types = 'temp'
        this.ticket_order_no = ''
        this.business_company = ''
        this.maintain_user = ''
        this.lingongchengs = ''
        this.customer_name = ''
        this.temporderlists()
      } else if (tab.name === 'contract') {
        this.heticket_order_no = ''
        this.hecustomer_name = ''
        this.hegongchengs = ''
        this.hebian = ''
        this.hename = ''
        this.hebusiness_company = ''
        this.hemaintain_user = ''
        this.types = 'contract'
        this.contractorderlists()
      }
    },

    // 临时维修
    async temporderlists() {
      const { data } = await Workorderlist({
        ...this.temppageData,
        maintain_type: 'temp',
        ticket_order_no: this.ticket_order_no,
        business_company: this.business_company,
        maintain_user: this.maintain_user,
        customer_name: this.customer_name
      })
      //   console.log(data)
      this.tableData = data.data.data
      this.temppageData.count = data.data.count
      this.temppageData.page = data.data.page * 1
    },

    // 合同维修
    async contractorderlists() {
      const { data } = await Workorderlist({
        ...this.contractpageData,
        maintain_type: 'contract',
        ticket_order_no: this.heticket_order_no,
        customer_name: this.hecustomer_name,
        business_company: this.hebusiness_company,
        maintain_user: this.hemaintain_user
      })
      //   console.log(data)
      this.contableData = data.data.data
      this.contractpageData.count = data.data.count
      this.contractpageData.page = data.data.page * 1
    },

    // 新售后工单详情
    async Workdetails(order_id) {
      const { data } = await Workdetail({ order_id: order_id })
      // console.log(data)
      this.worklist = data.data
    },

    // 业务公司
    async Optionss() {
      const { data } = await Options()
      //   console.log(data)
      this.companis = data.data.companis
    },

    // 工程师
    async Getrenlist() {
      const { data } = await Getren()
      this.renlist = data.data
    },

    // 工程师
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    }
  }
}
</script>

<style scoped lang='less'>
.tongs {
  width: 85%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ebeef5;
  margin-left: 10%;
  box-sizing: border-box;
  padding-left: 2%;
}
.nav {
  width: 100%;
  display: flex;
  //   justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  .main {
    width: 30%;
    // border: 1px solid #000;
    height: 100%;
    margin-left: 5px;
    display: flex;
    align-items: center;
    .titles {
      width: 17%;
      font-size: 13px;
    }
  }
}
.hint {
  width: 100%;
  font-size: 16px;
  color: red;
  box-sizing: border-box;
  padding-left: 10%;
  font-weight: bold;
}
</style>